<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-btn text plain class="pa-0 align-center" @click="goBack"><v-icon>mdi-arrow-left</v-icon>{{ $t('ButtonsLabel.GoBack') }}</v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters align="center" class="mt-4">
      <v-col cols="12" sm="8">
        <v-row no-gutters class="text-h4 font-weight-bold">
          <p>{{ report.reportTitle }}</p>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters justify="center">
      <v-col cols="12">
        <v-simple-table dense class="noHoverEffect pa-2 mt-4">
          <tbody>
            <tr>
              <td class="font-weight-bold" style="width: 200px">{{ $t('ReportHeaders.Title') }}</td>
              <td class="pa-4">{{ report.reportTitle }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold" style="width: ">{{ $t('ReportHeaders.Creator') }}</td>
              <td v-if="isAdmin">
                <router-link :to="{ name: 'UserDetails', params: { Id: report.creator.id } }">{{ report.creator.name }}</router-link>
              </td>
              <td v-else>
                {{ report.creator.name }}
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold" style="width: ">{{ $t('ReportHeaders.Created') }}</td>
              <td class="pa-4">{{ $util.formatDate(report.created, true) }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold pa-4">{{ $t('ReportHeaders.ProjectStatus') }}</td>
              <td class="pa-4">{{ $util.projectStatusByNumber(report.projectStatus) }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold pa-4">{{ $t('ReportHeaders.Summary') }}</td>
              <td class="pa-4">{{ report.summary }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold pa-4">{{ $t('ReportHeaders.ReportText') }}</td>
              <td class="pa-4">{{ report.reportText }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold pa-4">{{ $t('ReportHeaders.Challanges') }}</td>
              <td class="pa-4">{{ report.challenges }}</td>
            </tr>
            <tr v-for="(item, i) in report.resources" :key="i">
              <td class="font-weight-bold pa-4">{{ $t('ReportHeaders.Resources') }}</td>
              <td class="">
                <v-row no-gutters align="center">
                  <v-col cols="8">
                    <p class="pt-4">{{ item.linkDescription }}</p>
                  </v-col>
                  <v-col cols="4" align="right">
                    <v-btn v-if="item.link" :href="item.link" target="_blank">See Link</v-btn>
                  </v-col>
                </v-row>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import apiService from '@/services/apiService.js';
export default {
  data: () => ({
    report: null,
    loading: false,
  }),
  computed: {
    ...mapGetters(['currentProjectId', 'isManager', 'isAdmin', 'darkMode', 'user']),
    HasFullAccessPermissions() {
      if (this.isAdmin) {
        return true;
      }
      if (this.$util.HasFullAccess(this.user, this.report.projectPermissions) == false) {
        return false;
      } else if (this.isManager) {
        return true;
      } else {
        return true;
      }
    },
  },
  methods: {
    goBack() {
      this.$router.push({ name: 'rapports' });
    },
    async getReport() {
      this.loading = true;
      this.report = await apiService.getReportById(this.$route.params.reportId);
      this.loading = false;
    },
    async EditReport() {
      this.loading = true;
      await apiService.updateReport(this.report).then(() => {
        this.getReport();
      });
    },
  },
  created() {
    this.getReport();
  },
};
</script>

<style>
</style>